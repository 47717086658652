import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const ProfileIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #c53534;
  margin-right: 30px;
`;

export const MainBox = styled.header`
  position: relative;
  background: #eb524a;
  border-radius: 5px;
  margin: auto;

  > div {
    display: flex;
    position: relative;
    padding: 30px;

    h1 {
      font-size: 20px;
    }

    p {
      margin-top: 10px;
      letter-spacing: 1px;
      line-height: 20px;
    }
  }

  > footer {
    background-color: #c53534;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    height: 40px;
    overflow: hidden;
    padding-left: 30px;
  }

  button {
    height: 36px;
    width: 150px;
    border-radius: 50px;
    background-color: #ffb72c;
    border: 0;
    color: #eb524a;
    position: absolute;
    bottom: -18px;
    left: 160px;
    font-weight: 500;
    box-shadow: 0px 5px 8px rgb(0, 0, 0, 0.25);
  }

  ul {
    list-style: none;
  }
`;

export const ContactItem = styled.div`
  background: #fff;
  margin: 10px 0;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  color: ${props => props.color};
  display: flex;
  align-items: center;

  svg:nth-child(1) {
    color: #fff;
    display: flex;
    padding: 10px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: ${props => props.color};
  }

  div {
    margin-left: 20px;
    flex-grow: 1;

    a {
      strong {
        font-size: 18px;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-decoration: none;
      color: ${props => props.color};
      line-height: 30px;
      letter-spacing: 0.8px;
    }
  }

  svg:nth-child(3) {
    width: 30px;
    height: 30px;
    color: ${props => props.color};
  }
`;

export const ContactIcon = styled.span``;
