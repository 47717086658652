import React, { Component } from 'react';

import {
  TiUser,
  TiSocialLinkedin,
  TiSocialGithub,
  TiMail
} from 'react-icons/ti';

import { BsChevronUp, BsChevronRight } from 'react-icons/bs';
import { motion } from 'framer-motion';
import AngelList from '../../components/CustomIcons/AngelList';

import { MainWrapper, MainBox, ProfileIcon, ContactItem } from './style';

export default class Main extends Component {
  state = {
    isOpen: false
  };

  handleContact = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen
    });
  };

  render() {
    const { isOpen } = this.state;

    const mainBoxVariants = {
      moveUp: {},
      moveDown: {}
    };

    const buttonVariants = {
      open: {
        width: 60,
        height: 60,
        x: 230,
        y: -30,
        transition: {
          delay: 0.4,
          type: 'tween'
        }
      },
      closed: {
        x: 0,
        y: 0,
        height: 36,
        width: 150,
        transition: { delay: 0.7, type: 'tween' }
      }
    };

    const buttonTextVariants = {
      hide: { opacity: 0, display: 'none', transition: { delay: 0.4 } },
      show: { opacity: 1, display: 'block', transition: { delay: 1.2 } }
    };

    const buttonIconVariants = {
      hide: { opacity: 0, display: 'none', transition: { delay: 0.6 } },
      show: { opacity: 1, display: 'block', transition: { delay: 0.4 } }
    };

    const footerVariants = {
      showFooter: { height: 40, transition: { type: 'tween', delay: 0.4 } },
      hideFooter: { height: 0, transition: { delay: 0.7 } }
    };

    const footerTextVariant = {
      show: {
        opacity: 1,
        transition: { delay: 0.2 }
      },
      hide: {
        opacity: 0,
        transition: { delay: 0.6 }
      }
    };

    const listVariant = {
      hidden: {
        transition: {
          staggerChildren: 0.2,
          staggerDirection: -1,
          when: 'afterChildren'
        },
        height: 0
      },
      show: {
        opacity: 1,
        display: 'block',
        height: 450,
        transition: {
          staggerChildren: 0.3,
          delayChildren: 0.7
        }
      }
    };

    const listItemVariant = {
      hidden: {
        y: 50,
        opacity: 0,
        transition: {
          y: { stiffness: 1000 }
        },
        transitionEnd: {
          display: 'none'
        }
      },
      show: {
        y: 0,
        opacity: 1,
        display: 'block',
        transition: {
          y: { stiffness: 1000 }
        }
      }
    };

    return (
      <MainWrapper>
        <motion.div
          animate={isOpen ? 'moveUp' : 'moveDown'}
          variants={mainBoxVariants}
          initial="moveDown"
        >
          <MainBox>
            <div>
              <ProfileIcon>
                <TiUser size={60} color="#aa1a19" />
              </ProfileIcon>
              <div>
                <h1>João Paulo da Silva</h1>
                <p>Javascript Engineer</p>
              </div>
            </div>

            <motion.footer
              animate={isOpen ? 'showFooter' : 'hideFooter'}
              variants={footerVariants}
              initial="hideFooter"
            >
              <motion.span
                animate={isOpen ? 'show' : 'hide'}
                variants={footerTextVariant}
              >
                CONTACT ME
              </motion.span>
            </motion.footer>

            <motion.button
              animate={isOpen ? 'open' : 'closed'}
              variants={buttonVariants}
              onClick={this.handleContact}
            >
              <motion.span
                animate={isOpen ? 'hide' : 'show'}
                variants={buttonTextVariants}
              >
                CONTACT ME
              </motion.span>

              <motion.span
                animate={isOpen ? 'show' : 'hide'}
                initial="hide"
                variants={buttonIconVariants}
              >
                <BsChevronUp size={30} />
              </motion.span>
            </motion.button>
          </MainBox>
        </motion.div>

        <motion.ul
          animate={isOpen ? 'show' : 'hidden'}
          initial="hidden"
          variants={listVariant}
        >
          <motion.li
            variants={listItemVariant}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ContactItem color="#c53534">
              <TiMail size={40} />
              <div>
                <a
                  href="mailto:hi@joaopaulo.dev"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>Email</strong>
                  hi@joaopaulo.dev
                </a>
              </div>
              <BsChevronRight />
            </ContactItem>
          </motion.li>
          <motion.li
            variants={listItemVariant}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ContactItem color="#333">
              <TiSocialGithub size={40} />
              <div>
                <a
                  href="https://github.com/jpaulodev"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>Github</strong>
                  https://github.com/jpaulodev
                </a>
              </div>
              <BsChevronRight />
            </ContactItem>
          </motion.li>
          <motion.li
            variants={listItemVariant}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ContactItem color="#000">
              <AngelList />
              <div>
                <a
                  href="https://angel.co/jpaulodev"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>Angellist</strong>
                  https://angel.co/jpaulodev
                </a>
              </div>
              <BsChevronRight />
            </ContactItem>
          </motion.li>
          <motion.li
            variants={listItemVariant}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <ContactItem color="#2867B2">
              <TiSocialLinkedin size={40} />
              <div>
                <a
                  href="https://linkedin.com/in/jpaulodev"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>Linkedin</strong>
                  https://linkedin.com/in/jpaulodev
                </a>
              </div>
              <BsChevronRight />
            </ContactItem>
          </motion.li>
        </motion.ul>
      </MainWrapper>
    );
  }
}
